import {
    CHANGE_SELECTED_DAY
} from '../actions/appointment.actions';
import moment from 'moment';

const appointment_reducer = (state = {
    selectedDay: moment().format('YYYY-MM-DD'),
}, action) => {
    switch (action.type) {
        case CHANGE_SELECTED_DAY:
            return { ...state, selectedDay: action.payload };
        default:
            return state;
    }
}

export default appointment_reducer;