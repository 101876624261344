import React from 'react';
import { Link } from 'react-router-dom';
import { getStaffFullname } from '../share/stringHelper';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import '../styles/dropdown.css'
import LogoutIcon from 'assets/img/menu/arrow-down.png';
import { getStaffImageUrl } from '../../src/share/donorHelper';
import { storage } from '../share/constants'

const menu = (
    <Menu>
      <MenuItem key="2" onClick={async () => {
          await localStorage.removeItem(storage.TOKEN)
          await localStorage.removeItem(storage.USER)
          await localStorage.removeItem(storage.PLASMA_CENTER_GENERAL_SETTING)
          await localStorage.removeItem(storage.CURRENT_SELECTED_PLASMA_CENTER)
          await localStorage.removeItem(storage.CURRENT_SELECTED_PLASMA_CENTER_ID)
          window.location = '/login'
      }}>Logout</MenuItem>
      <MenuItem key="3" onClick={async () => {
          window.location = '/user/change-password'
      }}>Change Password</MenuItem>
    </Menu>
);

const UserMenu = ({ logged_in_user }) => {
    if(!logged_in_user) {
        return <li></li>
    }

    return (
        <li className="user user-menu">
            {/* <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                <img src={UserIcon} className="user-image" alt=""/>
                <span className="hidden-xs strong-text">{getDonorFullname(logged_in_user)}</span><br />
                <small>{logged_in_user.staffID}</small>
            </Link> */}
            <Dropdown
                trigger={['click']}
                overlay={menu}
                animation="slide-up"
            >
                <Link to="#">
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex" }}>
                                        <img src={getStaffImageUrl(logged_in_user)} className="user-image" alt=""/>
                                    </div>
                                    <div>
                                        <span>
                                            {getStaffFullname(logged_in_user)}
                                            <br/>
                                            <small>{logged_in_user.staffID}</small>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ paddingLeft: 10}}>
                                        <img src={LogoutIcon} alt="arrow-down" className="icon-arrow-down"/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                </Link>
            </Dropdown>
        </li>
    )
}

export default UserMenu