import React from 'react';
import { isRoleHeadquarter } from '../share/accessRightHelper'

class Location extends React.Component {

    render() {
        if(!this.props.logged_in_user) {
            return <li></li>
        }
        const { specifyAreas, roles } = this.props.logged_in_user

        // if(roles && roles[0] && roles[0].name === "Executive") return null;

        return (
            <li style={{padding: "6px 10px 16px 25px"}}>
                <span className="navbar-role">Managing:</span>
                <br />
                <span className="navbar-managing">
                    {isRoleHeadquarter(roles) ? 
                        'All Centers' : specifyAreas.map(r => r.name).join(',')
                    }
                </span>
            </li>
        );
    }
}

export default Location;