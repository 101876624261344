import axios from 'axios'
import { BASE_URL } from '../config';
import { storage } from '../share/constants';
import { LOGIN_URL } from './URLs';
import AES from 'crypto-js/aes';

const instance = axios.create({
    baseURL: BASE_URL
})

instance.interceptors.request.use(
    async (config) => {
        const token = atob(localStorage.getItem(storage.TOKEN))
        if(token) {
            config.headers.authorization = token.replace(/['"]+/g, '')
        }

        // Remove all params in query string and add it to header instead with enctypt 
        const params = config.params;
        if(params) {
          var ciphertext = AES.encrypt(JSON.stringify(params), 'csl-webridge-@168').toString();
          config.headers.qst = ciphertext
        }
        config.params = null;

        return config
    },
    (err) => {
        return Promise.reject(err)
    }
)

instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const response = error.response;
      if (response && response.config.url !== LOGIN_URL && response.status === 401) {
        window.location = '/login';
      }
      return Promise.reject(error);
    },
  );

export default instance