import React from 'react';
import Logo from '../Logo';
import Location from '../Location';
import Role from '../Role';
import UserMenu from '../UserMenu';
import SidebarToggle from '../SidebarToggle';
import { connect } from 'react-redux';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            scrolledClass: ""
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            let c = "";
            if(window.scrollY === 0){
                c = "";
            } else {
                c = "navbar-scrolled";
            }
            this.setState({ scrolledClass: c });
        });
    }

    render() {
        var header = (this.props.layout === "NoSidebarLayout") ? "main-header main-header-lc" : "main-header";
        var navPageTitle = (this.props.layout === "NoSidebarLayout") ? "navbar-page-title navbar-page-title-lc" : "navbar-page-title";
        var isNoSidebarLayout = (this.props.layout === "NoSidebarLayout") ? "" : this.state.scrolledClass;

        return (
            <header className={header}>
                <Logo isNoSidebarLayout={(this.props.layout === "NoSidebarLayout") ? true : false}/>
                <nav className={"navbar navbar-static-top main-navbar " + isNoSidebarLayout} role="navigation">
                    <div className={navPageTitle}>
                        {/* <ul>
                            <li className={posAddr}>
                                <span className="navbar-role">Role:&nbsp;&nbsp;</span>
                                <span className="navbar-managing">Divisional Manager</span>
                            </li>
                            <li className={posAddr}>
                                <span className="navbar-role">Managing:&nbsp;&nbsp;</span>
                                <span className="navbar-managing">Florida, United States</span>
                            </li>
                        </ul> */}
                    </div>

                    <div className="navbar-custom-menu">
                        <ul className="nav navbar-nav">
                            <Role logged_in_user={this.props.logged_in_user}/>
                            <Location logged_in_user={this.props.logged_in_user}/>
                            <UserMenu logged_in_user={this.props.logged_in_user}/>
                            <SidebarToggle/>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

const mapStateToProps = (state) => ({
    logged_in_user: state.staff_reducer.logged_in_user
})

export default connect(mapStateToProps)(Header);