import { CHANGE_CURRENT_SELECTED_PLASMA_CENTER } from "../actions/staff.actions";
import { storage } from "../../share/constants";

const staff_reducer = (state = {
    logged_in_user: null,
    current_selected_plasma_center: localStorage.getItem(storage.CURRENT_SELECTED_PLASMA_CENTER) ? JSON.parse(atob(localStorage.getItem(storage.CURRENT_SELECTED_PLASMA_CENTER))) : null
}, action) => {
    switch (action.type) {
        case CHANGE_CURRENT_SELECTED_PLASMA_CENTER:
            localStorage.setItem(storage.CURRENT_SELECTED_PLASMA_CENTER, btoa(JSON.stringify(action.payload)))
            return { ...state, current_selected_plasma_center: action.payload }
        case 'UPDATE_LOGGED_IN_USER':
            return { ...state, logged_in_user: action.payload }
        case 'CLEAR_CURRENT_SELECTED_PLASMA_CENTER':
            // Save id to local storage to use after get center auto select
            if(state.current_selected_plasma_center) {
                localStorage.setItem(storage.CURRENT_SELECTED_PLASMA_CENTER_ID, state.current_selected_plasma_center._id)
            }
            return { ...state, current_selected_plasma_center: null }
        default:
            return state;
    }
}

export default staff_reducer;