import { Header, Sidebar } from 'components/Layout';
import React from 'react';
import { connect } from 'react-redux';
import { storage } from '../../share/constants';
import { getMenuByPath } from '../SidebarLeft';
import { canViewAR } from '../../share/accessRightHelper';

class MainLayout extends React.Component {

    constructor(props) {
        super(props)

        const loggedInUserEncrypted = localStorage.getItem(storage.USER)
        // Check if no token redirect to login
        if(!localStorage.getItem(storage.TOKEN) || !loggedInUserEncrypted) {
            window.location = "/login"
        }
        this.loggedInUser = JSON.parse(atob(loggedInUserEncrypted));

        // Restore plasma general setting form local storage
        window.generalSetting = localStorage.getItem(storage.PLASMA_CENTER_GENERAL_SETTING)? JSON.parse(atob(localStorage.getItem(storage.PLASMA_CENTER_GENERAL_SETTING))) : null

        this.state = {
            openSilder: ""
        };
    }

    isCanOpenThisPage() {
        const activeMenu = getMenuByPath(window.location.pathname)
        if(activeMenu) {
            if(!canViewAR(this.loggedInUser.roles, activeMenu.id)) {
                window.location = "/login"
            }
        }
    }

    componentDidMount() {
        let toggleBarSilder = document.getElementsByClassName("toggle-menubar")[0];
        if (toggleBarSilder !== undefined) {
            toggleBarSilder.addEventListener("click", function(e){
                let className = document.getElementsByClassName("sidebar-open")[0];
                if (className === undefined) {
                   this.setState({ openSilder: "sidebar-open" })
                } else {
                   this.setState({ openSilder: "" })
                }
            }.bind(this));
        }

        this.props.dispatch({
            type: 'UPDATE_LOGGED_IN_USER',
            payload: JSON.parse(atob(localStorage.getItem(storage.USER)))
        })
    }

    render() {
        this.isCanOpenThisPage()
        const { children } = this.props;
        return (
            <div className={"skin-black fixed sidebar-mini " + this.state.openSilder }>
                <div className="wrapper">
                    <Header layout="MainLayout"/>
                    <Sidebar/>
                    {children}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapDispatchToProps)(MainLayout);