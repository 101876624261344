import moment from "moment"
import lrz from 'lrz';

export const getPhoneNumberAsString = (phoneObj) => {
    if (!phoneObj) {
        return ""
    }

    return phoneObj.countryCode + phoneObj.phoneNumber
}

export const getMailingAddressAsString = (addressesObj) => {
    if (!addressesObj) {
        return ""
    }
    const addressObj = addressesObj
    let addressName = addressObj.addressLine1
    if (addressObj.addressLine2) {
        addressName += ", " + addressObj.addressLine2
    }

    if (addressObj.city) {
        addressName += ", " + addressObj.city
    }

    if (addressObj.state) {
        addressName += ", " + addressObj.state
    }

    if (addressObj.country) {
        addressName += ", " + addressObj.country
    }

    return addressName
}

export const getDonorFullname = (donorObj) => {
    if (!donorObj) {
        return "Unknown Donor"
    }

    return donorObj.fullName
}

export const getStaffFullname = (staffObj) => {
    if (!staffObj) {
        return "Unknown Staff"
    }

    let name = staffObj.firstName + " " + staffObj.lastName
    if (staffObj.middleName) {
        name = staffObj.firstName + " " + staffObj.middleName + " " + staffObj.lastName
    }

    return name
}

export const isLike = (a, b) => {
    try {
        return a.toLowerCase().indexOf(b.toLowerCase()) > -1
    } catch (e) {
        return false
    }
}

/**
 * Compare pathname url
 * 
 * @param {string} activeItem 
 * @param {string} route 
 * 
 * @returns boolean
 */
export const isSameRoute = (activeItem, route) => {
    if (activeItem === route) {
        return true
    }

    if (activeItem != null &&
        activeItem.split('/').length > 2 &&
        route !== '/') {
        return activeItem.includes(route)
    }

    return false
}

export const textTruncate = (str, length, ending) => {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

export const ordinalSuffixOf = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }

    return i + "th";
}

export const getDateFromDateTime = (str) => {
    let date = str.split('T')
    if (date.length > 0) {
        return moment(date[0])
    }

    return moment()
}

export const increaseNumberOneStep = arr => {
    var num = Math.max(...arr)
    var numStr = num + ""
    let prev = "1"
    let minimal = prev.padEnd(numStr.length, "0") - 0

    return num + (minimal - 0) / 2;
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const canvas = document.createElement('canvas');
        const img = document.createElement('img');
        img.src = reader.result;
        // console.log(reader.result);
        img.onload = () => {
            const ctx = canvas.getContext('2d');
            let originImageWidth = img.width, originImageHeight = img.height;
            let maxHeight = 1024;
            let targetWidth = originImageWidth, targetHeight = originImageHeight;
            if (originImageHeight > maxHeight) {
                targetHeight = maxHeight;
                targetWidth = Math.round(maxHeight * (originImageWidth / originImageHeight));
            }
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            ctx.clearRect(0, 0, targetWidth, targetHeight);
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
            const imageDataURL = canvas.toDataURL(file.type || 'image/jpeg', 1);
            const imageBytes = window.atob(imageDataURL.split(',')[1]);
            const arrayBuffer = new ArrayBuffer(imageBytes.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < imageBytes.length; i++) {
                uint8Array[i] = imageBytes.charCodeAt(i);
            }
            let mimeType = imageDataURL.split(',')[0].match(/:(.*?);/)[1];
            let newFile = new File([uint8Array], file.name, { type: mimeType || 'image/jpeg' });
            var quality = 1;
            if (newFile.size > 1024 * 1024) {
                quality = 0.6;
            }
            lrz(newFile, { quality: quality }).then((rst) => {
                resolve(rst.base64);
            })
        };
    };
    reader.onerror = error => reject(error);
})