import { put, takeLatest, all } from 'redux-saga/effects';
import cslApi from '../../api/cslApi';
import { 
    FETCH_LOCATION_URL,
    FETCH_APPOINTMENT_BY_MONTH_URL
} from '../../api/URLs';
import { 
    FETCH_LOCATIONS, 
    FETCH_LOCATIONS_COMPLETE,
    FETCH_LOCATIONS_ERROR
} from '../actions/location.actions';
import { FETCH_APPOINTMENTS_BY_MONTH, FETCH_APPOINTMENTS_BY_MONTH_ERROR, FETCH_APPOINTMENTS_BY_MONTH_COMPLETE } from '../actions/calendar.actions';

// FETCH LOCATOINS
function* fetchLocations({ payload }) {
    const result = yield cslApi.get(FETCH_LOCATION_URL, {params: payload})
        .then(res => res.data)
        .catch(err => ({ error: true, message: err.message }))
    if(result.status === 0 || result.error) {
        yield put({ type: FETCH_LOCATIONS_ERROR, payload: result.message })
    } else {
        yield put({ type: FETCH_LOCATIONS_COMPLETE, payload: result.data })
    }
}

function* fetchLocationsWatcher() {
    yield takeLatest(FETCH_LOCATIONS, fetchLocations);
}

// FETCH APPOINTMENTS BY MONTH
function* fetchAppointmentsByMonth({ payload }) {
    const result = yield cslApi.get(FETCH_APPOINTMENT_BY_MONTH_URL, {params: payload})
        .then(res => res.data)
        .catch(err => ({error: true, message: err.message}))
    
    if(result.status === 0) {
        yield put({ type: FETCH_APPOINTMENTS_BY_MONTH_ERROR, payload: result.message })
    } else {
        yield put({ type: FETCH_APPOINTMENTS_BY_MONTH_COMPLETE, payload: result.data })
    }
}

function* fetchAppointmentsByMonthWatcher() {
    yield takeLatest(FETCH_APPOINTMENTS_BY_MONTH, fetchAppointmentsByMonth);
}

export default function* locationSaga() {
    yield all([
        fetchLocationsWatcher(),
        fetchAppointmentsByMonthWatcher()
    ]);
}