import React from 'react';
import SidebarLeft from '../SidebarLeft';

class Sidebar extends React.Component {

    render() {
        return (
            <aside style={{overflowY: "scroll"}} className="main-sidebar disable-scrollbars">
                <section className="sidebar">
                    <SidebarLeft/>
                </section>
            </aside>
        );
    }
}

export default Sidebar;