import React from 'react'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import Tcss  from '../styles/tooltip-page.module.css'

const Explanation = ({
    style,
    children,
    size,
    title
}) => {
    return (
        <div className={Tcss["tooltip-bottom"]} style={style}>
            <IoIosHelpCircleOutline size={size ? size : 20} color="#c4c4d2"/>
            <span className={"text-time "+Tcss["tooltiptext"]}>
                {title ? <span><h5><b>{title}</b></h5></span> : null }
                <span>{children}</span>
            </span>
        </div>
    )
}

export default Explanation