import React from 'react';
import LogoIcon from 'assets/img/logo.jpeg';
import { FaBars } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { storage } from '../share/constants';
import { getFirstAvailbleRoute } from './SidebarLeft';

class Logo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hideToggle: false
        }

        const loggedInUserEncrypted = localStorage.getItem(storage.USER)
        this.loggedInUser = JSON.parse(atob(loggedInUserEncrypted));
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({hideToggle: window.innerWidth <= 1024});
    }
    render() {
        const { isNoSidebarLayout } = this.props
        return (
            <div className="main-navLogo">
                <div className={this.state.hideToggle ? "toggle-menubar togglebar-silder" : "toggle-menubar hide"}>
                    {isNoSidebarLayout ? "" : <FaBars/>}
                </div>
                <Link to={getFirstAvailbleRoute(this.loggedInUser.roles)} className="logo">
                    <span className="logo-mini"><b>A</b>LI</span>
                    <img src={LogoIcon} width="60%" alt=""/>
                </Link>
            </div>
        );
    }
}

export default Logo;