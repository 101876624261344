import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { MainLayout, EmptyLayout } from 'components/Layout';
import './styles/main.css';
import ConfirmModal from './components/Modal/ConfirmModal';
import MessageModal from './components/Modal/MessageModal';
import PageLoading from './components/PageLoading';

const DashboardPage = React.lazy(() => import('./pages//DashboardPage'));
const Referral = React.lazy(() => import('./pages/referral/ReferralInputPage'));
const ReferralDetail = React.lazy(() => import('./pages/referral/ReferralQrcodePage'));

const PromotionStatisticPage = React.lazy(() => import('./pages/promotion/statistics/Statistic'))
const PromotionStatisticDetailPage = React.lazy(() => import('./pages/promotion/statistics/StatisticDetail'))
const PromotionReviewDetailPage = React.lazy(() => import('./pages/promotion/review/ReviewDetail'))
const CreatePromotionPage = React.lazy(() => import('./pages/promotion/create/createPromotionPage'))
const PromotionListPage = React.lazy(() => import('./pages/promotion/list/ListPromotionPage'))
const ReferalPage = React.lazy(() => import('./pages/referral/ReferalPage'))

const LoginPage = React.lazy(() => import('./pages/LoginPage'))
const ForgetPasswordPage = React.lazy(() => import('./pages/ForgetPasswordPage'))
const ResetPasswordPage = React.lazy(() => import('./pages/ResetPasswordPage'))
const SetPasswordSuccessPage = React.lazy(() => import('./pages/SetPasswordSuccessPage'))
const LocationPlasmaPage = React.lazy(() => import('./pages/locations/plasma/PlasmaPage'))
const LocationCreatePlasmaPage = React.lazy(() => import('./pages/locations/plasma/CreatePage'))
const LocationPlasmaCenterViewRate = React.lazy(() => import('./pages/locations/plasma/ViewRate'))
const LocationPlasmaCenterFeedbackHistory = React.lazy(() => import('./pages/locations/plasma/FeedbackHistory'))
const LocationPlasmaCenterAllFeedbackHistory = React.lazy(() => import('./pages/locations/plasma/AllFeedbackHistory'))
const CurrentFeeStructurePage = React.lazy(() => import('./pages/locations/feestructure/CurrentFeeStructurePage'))
const UpComingFeeStructurePage = React.lazy(() => import('./pages/locations/feestructure/UpComingFeeStructurePage'))
const ExpiredFeeStructurePage = React.lazy(() => import('./pages/locations/feestructure/ExpiredFeeStructurePage'))
const CreateFeeStructurePage = React.lazy(() => import('./pages/locations/feestructure/CreateFeeStructurePage'))

const MemberPages = React.lazy(() => import('pages/members/MemberPage'));
const MemberHistoryDonatedPage = React.lazy(() => import('pages/members/histories/DonatedPage'));
const MemberHistoryPointPage = React.lazy(() => import('pages/members/histories/PointPage'));
const MemberHistoryPayOutPage = React.lazy(() => import('pages/members/histories/PayOutPage'));
const EmployeePage = React.lazy(() => import('./pages/employee/employee/EmployeePage'));
const EmployeeCreatePage = React.lazy(() => import('./pages/employee/employee/CreatePage'));
const EmployeeEditPage = React.lazy(() => import('./pages/employee/employee/EditPage'));
const EmployeeRolePage = React.lazy(() => import('./pages/employee/role/RolePage'));

const ContentGroupPage = React.lazy(() => import('./pages/contents/GroupSetting/GroupPage'));
const ContentDonationRatingPage = React.lazy(() => import('./pages/contents/DonationRatingPage'));
const AppHealthLinkPage = React.lazy(() => import('./pages/contents/HealthLinkPage'));
const SetLicenseAgreement = React.lazy(() => import('./pages/contents/SetLicenseAgreement'))
const HealthProgramListPage = React.lazy(() => import('./pages/contents/HealthProgramListPage'))
const CreateHealthProgramPage = React.lazy(() => import('./pages/contents/CreateHealthProgramPage'))
const StaticPagesListPage = React.lazy(() => import('./pages/contents/StaticPagesListPage'))
const CreateStaticPagesPage = React.lazy(() => import('./pages/contents/CreateStaticPagesPage'))
const SetAppFeedbackPage = React.lazy(() => import('./pages/contents/SetAppFeedbackPage'))
const SetRedeemPage = React.lazy(() => import('./pages/contents/SetRedeemPage'))

const RewardGamePage = React.lazy(() => import('./pages/rewardGame/RewardGamePage'))
const CreateDonorChancePage = React.lazy(() => import('./pages/rewardGame/CreateDonorChancePage'))
const DonorGroupChancePage = React.lazy(() => import('./pages/rewardGame/DonorGroupChancePage'))
const CommunicationPage = React.lazy(() => import('./pages/communication/CommunicationPage'))
const AnnouncementPage = React.lazy(() => import('./pages/announcement/AnnouncementPage'))
const NotificationPage = React.lazy(() => import('./pages/notification/NotificationPage'));
const VisitReminderPage = React.lazy(() => import('./pages/appointments/VisitReminderPage'));

const ChangePasswordPage = React.lazy(() => import('./pages/ChangePasswordPage'));

const RewardProgramsPage = React.lazy(() => import('./pages/rewards/programs/RewardPrograms'));
const RewardBonusesPage = React.lazy(() => import('./pages/rewards/bonuses/RewardBonuses'));
const RewardRedeemPage = React.lazy(() => import('./pages/rewards/redeem/RewardRedeem'));
const RewardAdjustPage = React.lazy(() => import('./pages/rewards/adjust/RewardAdjust'));
const RewardSettingsPage = React.lazy(() => import('./pages/rewards/settings/RewardSettings'));
const RewardViewPage = React.lazy(() => import('./pages/rewards/components/RewardView'));
const RewardFastCashPage = React.lazy(() => import('./pages/rewards/fastCash/RewardFastCashPage'));

const AppFeedbackPage = React.lazy(() => import('./pages/appFeedback/AppFeedback'));
const WhyDonatePage = React.lazy(() => import('./pages/whyDonate/WhyDonate'));

// const DownLoadAppPage = React.lazy(() => import('./pages/downLoadApp/IndexPage'));

const PlasmaNewsEventPage = React.lazy(() => import('./pages/locations/plasma/NewsEventPage'));
const QRCodeRedirect = React.lazy(() => import('./pages/QRCodeRedirect'));

const RedemptionPage = React.lazy(() => import('./pages/redemtions/RedemtionPage'));
const ProductPage = React.lazy(() => import('./pages/redemtions/ProductPage'));
const ProductFormPage = React.lazy(() => import('./pages/redemtions/ProductFormPage'));
const FastCashPage = React.lazy(() => import('./pages/redemtions/FastCashPage'));
const RedeemSettingPage = React.lazy(() => import('./pages/redemtions/RedeemSettingPage'));

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path={[
                        '/',

                        // Marketing
                        "/promotion/create/:type",
                        '/promotion/list/news/approved',
                        '/promotion/list/campaign/approved',
                        '/promotion/list/survey/approved',
                        '/promotion/list/news/processing',
                        '/promotion/list/campaign/processing',
                        '/promotion/list/survey/processing',
                        '/promotion/list/news/published',
                        '/promotion/list/campaign/published',
                        '/promotion/list/survey/published',
                        '/promotion/review',
                        '/promotion/statistic',
                        '/promotion/statisticdetail',
                        '/promotion/commentdetail',

                        // Rewards
                        "/rewards/programs",
                        "/rewards/bonuses",
                        "/rewards/redeem",
                        "/rewards/adjust",
                        "/rewards/settings",
                        "/rewards/view",
                        "/rewards/fastcash",

                        // App Feedback
                        "/appfeedback",

                        // Why Donate
                        "/whydonate",

                        // Notification Management
                        "/notification",

                        // Announcement
                        "/announcement",

                        // Communication
                        "/communication",

                        /// Reward Game
                        "/rewardgame/rewarditem",
                        "/rewardgame/gamecoupon",
                        "/rewardgame/donorchance",
                        "/rewardgame/donorgroupchance",
                        "/rewardgame/create",
                        "/rewardgame/view",

                        // Center Managemnt
                        "/location",
                        "/location/plasma/create",
                        "/location/plasma/view-rate",
                        "/location/plasma/feedback-history",
                        "/location/plasma/all-feedback-history/:locationID",
                        "/location/news-and-events",
                        "/location/plasma/fee-structure/current/:_id",
                        "/location/plasma/fee-structure/up-coming/:_id",
                        "/location/plasma/fee-structure/expired/:_id",
                        "/location/plasma/fee-structure/create/:_id",

                        // App User Management
                        "/member", 
                        "/member/point-history/:id",
                        "/member/donated-history/:id", 
                        "/member/payout-history/:id",

                        // Referral
                        "/referral-list",

                        // Visit Reminder
                        "/visitreminder",

                        // System Content
                        "/content/group",
                        "/content/app-donation-rating",
                        "/content/app-health-link",
                        "/content/license",
                        "/content/health-program-list",
                        "/content/health-program-create",
                        "/content/static-pages-list",
                        "/content/static-pages-create",
                        "/content/app-feedback",
                        "/content/redeem",

                        // Role & Employee
                        "/employee/create", 
                        "/employee/edit",
                        "/employee", 
                        "/employee/role",

                        // user change password
                        "/user/change-password",

                        // redemtions
                        "/redemptions",
                        "/redemptions/products",
                        "/redemptions/fast-cash",
                        "/redemptions/setting",
                        "/redemptions/products/create",
                        "/redemptions/products/edit"
                    ]}>
                        <MainLayout>
                            <React.Suspense fallback={<PageLoading />}>
                                <Route exact path="/" component={DashboardPage} />

                                {/* Marketing */}
                                <Route exact path="/promotion/create/:type" component={CreatePromotionPage} />

                                <Route exact path='/promotion/list/news/approved' component={PromotionListPage} />  
                                <Route exact path='/promotion/list/campaign/approved' component={PromotionListPage} />  
                                <Route exact path='/promotion/list/survey/approved' component={PromotionListPage} />  

                                <Route exact path='/promotion/list/news/processing' component={PromotionListPage} />  
                                <Route exact path='/promotion/list/campaign/processing' component={PromotionListPage} />  
                                <Route exact path='/promotion/list/survey/processing' component={PromotionListPage} />  

                                <Route exact path='/promotion/list/news/published' component={PromotionListPage} />  
                                <Route exact path='/promotion/list/campaign/published' component={PromotionListPage} />  
                                <Route exact path='/promotion/list/survey/published' component={PromotionListPage} />  

                                <Route exact path='/promotion/review' component={PromotionReviewDetailPage} />    
                                <Route exact path='/promotion/statistic' component={PromotionStatisticPage} />
                                <Route exact path='/promotion/statisticdetail' component={PromotionStatisticDetailPage} />

                                {/* Rewards */}
                                <Route exact path="/rewards/programs" component={RewardProgramsPage} />
                                <Route exact path="/rewards/bonuses" component={RewardBonusesPage} />
                                <Route exact path="/rewards/redeem" component={RewardRedeemPage} />
                                <Route exact path="/rewards/adjust" component={RewardAdjustPage} />
                                <Route exact path="/rewards/settings" component={RewardSettingsPage} />
                                <Route exact path="/rewards/view" component={RewardViewPage} />
                                <Route exact path="/rewards/fastcash" component={RewardFastCashPage} />

                                {/* App Feedback */}
                                <Route exact path="/appfeedback" component={AppFeedbackPage} />

                                {/* Why Donate */}
                                <Route exact path="/whydonate" component={WhyDonatePage} />


                                {/* Notifaction */}
                                <Route exact path="/notification" component={NotificationPage} />

                                {/* Announcement */}
                                <Route exact path="/announcement" component={AnnouncementPage} />

                                {/* communication */}
                                <Route exact path="/communication" component={CommunicationPage} />

                                {/* Reward Game */}
                                <Route exact path="/rewardgame/rewarditem" component={RewardGamePage} />
                                <Route exact path="/rewardgame/gamecoupon" component={RewardGamePage} />
                                <Route exact path="/rewardgame/donorchance" component={RewardGamePage} />
                                <Route exact path="/rewardgame/donorgroupchance" component={RewardGamePage} />
                                <Route exact path="/rewardgame/create" component={CreateDonorChancePage} />
                                <Route exact path="/rewardgame/view" component={DonorGroupChancePage} />

                                {/* Center Management */}
                                <Route exact path="/location" component={LocationPlasmaPage} />
                                <Route exact path="/location/plasma/create" component={LocationCreatePlasmaPage} />
                                <Route exact path="/location/plasma/view-rate" component={LocationPlasmaCenterViewRate} />
                                <Route exact path="/location/plasma/feedback-history" component={LocationPlasmaCenterFeedbackHistory} />
                                <Route exact path="/location/plasma/all-feedback-history/:locationID" component={LocationPlasmaCenterAllFeedbackHistory} />
                                <Route exact path="/location/news-and-events" component={PlasmaNewsEventPage} />
                                <Route exact path="/location/plasma/fee-structure/current/:_id" component={CurrentFeeStructurePage} />
                                <Route exact path="/location/plasma/fee-structure/up-coming/:_id" component={UpComingFeeStructurePage} />
                                <Route exact path="/location/plasma/fee-structure/expired/:_id" component={ExpiredFeeStructurePage} />
                                <Route exact path="/location/plasma/fee-structure/create/:_id" component={CreateFeeStructurePage} />

                                {/* App User Management */}
                                <Route exact path="/member" component={MemberPages} />
                                <Route exact path="/member/donated-history/:id" component={MemberHistoryDonatedPage} />
                                <Route exact path="/member/point-history/:id" component={MemberHistoryPointPage} />
                                <Route exact path="/member/payout-history/:id" component={MemberHistoryPayOutPage} />
                                
                                {/* Referal */}
                                <Route exact path="/referral-list" component={ReferalPage} />

                                {/* Visit Reminder */}
                                <Route exact path="/visitreminder" component={VisitReminderPage} />

                                {/* System Setting */}
                                <Route exact path="/content/group" component={ContentGroupPage} />
                                <Route exact path="/content/app-donation-rating" component={ContentDonationRatingPage} />
                                <Route exact path="/content/app-health-link" component={AppHealthLinkPage} />
                                <Route exact path="/content/license" component={SetLicenseAgreement} />
                                <Route exact path="/content/health-program-list" component={HealthProgramListPage} />
                                <Route exact path="/content/health-program-create" component={CreateHealthProgramPage} />
                                <Route exact path="/content/static-pages-list" component={StaticPagesListPage} />
                                <Route exact path="/content/static-pages-create" component={CreateStaticPagesPage} />
                                <Route exact path="/content/app-feedback" component={SetAppFeedbackPage} />
                                <Route exact path="/content/redeem" component={SetRedeemPage}/>

                                {/* Role & Employee */}
                                <Route exact path="/employee" component={EmployeePage} />
                                <Route exact path="/employee/role" component={EmployeeRolePage} />
                                <Route exact path="/employee/create" component={EmployeeCreatePage} />
                                <Route exact path="/employee/edit" component={EmployeeEditPage} />
                                
                                <Route exact path="/user/change-password" component={ChangePasswordPage} />

                                {/* redemtion management */}
                                <Route exact path="/redemptions" component={RedemptionPage} />
                                <Route exact path="/redemptions/products" component={ProductPage} />
                                <Route exact path="/redemptions/products/create" component={ProductFormPage} />
                                <Route exact path="/redemptions/products/edit" component={ProductFormPage} />
                                <Route exact path="/redemptions/fast-cash" component={FastCashPage} />
                                <Route exact path="/redemptions/setting" component={RedeemSettingPage} />
                            </React.Suspense>
                        </MainLayout>
                    </Route>

                    {/* Download page */}
                    {/* <Route 
                        exact 
                        path={["/download"]}>
                        <React.Suspense fallback={<PageLoading />}>
                            <Route exact path="/download" component={DownLoadAppPage} />
                        </React.Suspense>
                    </Route> */}

                    <Route exact path={[
                        "/referral", "/referral/detail/:id"
                    ]}>
                        <EmptyLayout className="hold-transition login-page">
                            <React.Suspense fallback={<PageLoading />}>
                                {/* Referral */}
                                <Route exact path="/referral" component={Referral} />
                                <Route exact path="/referral/detail/:id" component={ReferralDetail} />
                            </React.Suspense>
                        </EmptyLayout>
                    </Route>

                    <Route exact path={[
                        "/qrcode"
                    ]}>
                        <React.Suspense fallback={null}>
                            <Route exact path="/qrcode" component={QRCodeRedirect} />
                        </React.Suspense>
                    </Route>

                    <Route exact path={[
                        "/login", "/forget_password", "/set-new-password", "/set-password-success"
                    ]}>
                        <EmptyLayout className="hold-transition login-page">
                            <React.Suspense fallback={<PageLoading />}>
                                <Route exact path="/login" component={LoginPage} />
                                <Route exact path="/forget_password" component={ForgetPasswordPage} />
                                <Route exact path="/set-new-password" component={ResetPasswordPage} />
                                <Route exact path="/set-password-success" component={SetPasswordSuccessPage} />
                            </React.Suspense>
                        </EmptyLayout>
                    </Route>
                    <Redirect to="/login" />
                </Switch>
                <ConfirmModal />
                <MessageModal />
            </BrowserRouter>
        );
    }
}

export default App;
