// export const BASE_URL = process.env.BASE_URL;
// export const BASE_URL = "http://192.168.0.107:8081/admin";

// export const BASE_URL = "https://donorapp-apidev.cslplasma.com/admin";
// export const REFERRAL_BASE_URL = "https://donorapp-apidev.cslplasma.com/admin";
// export const BASE_URL = "https://donorapp-apiqa.cslg1.cslg.net/admin";
// export const BASE_URL = "https://donorapp-apiqa.cslg1.cslg.net/admin";
// export const BASE_URL = "https://donorapp-api.cslg1.cslg.net/admin";
// export const BASE_URL = "https://donorapp-apiqa.cslplasma.com/admin"

export const BASE_URL = "http://54.146.183.144/admin";
