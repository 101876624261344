import { combineReducers } from 'redux';
import staff_reducer from './staff.reducer';
import location_reducer from './location.reducer';
import appointment_reducer from './appointment.reducer';
import calendar_reducer from './calendar.reducer';
import confirm_modal_reducer from './confirm_modal.reducer';
import message_modal_reducer from './message_modal.reducer';

export default combineReducers({
    staff_reducer,
    location_reducer,
    appointment_reducer,
    calendar_reducer,
    confirm_modal_reducer,
    message_modal_reducer
});