import { 
    SHOW_MESSAGE_MODAL, 
    HIDE_MESSAGE_MODAL
} from "../actions/message_modal.actions";

const message_modal_reducer = (state = {
    show: false,
    message: '',
    autoDismiss: true
}, action) => {
    switch (action.type) {
        case SHOW_MESSAGE_MODAL:
            return { show: true, message: action.payload.message, autoDismiss: action.payload.autoDismiss, type: action.payload.type };
        case HIDE_MESSAGE_MODAL:
            return { show: false, message: '', autoDismiss: true}
        default:
            return state;
    }
}

export default message_modal_reducer;