import { SHOW_CONFIRM_MODAL, HIDE_CONFIRM_MODAL, CONFIRM_MODAL_SHOW_LOADING, CONFIRM_MODAL_HIDE_LOADING } from "../actions/confirm_modal.actions";

const confirm_modal_reducer = (state = {
    show: false,
    option: {
        title: "",
        message: "",
        buttonConfirmText: "Yes, Confirm",
        onConfirm: null,
        onCancel: null
    }
}, action) => {
    switch (action.type) {
        case SHOW_CONFIRM_MODAL:
            return { show: true, option: action.payload };
        case HIDE_CONFIRM_MODAL:
            return { ...state, show: false, loading: false }
        case CONFIRM_MODAL_SHOW_LOADING:
            return { ...state, loading: true}
        case CONFIRM_MODAL_HIDE_LOADING:
            return { ...state, loading: false}
        default:
            return state;
    }
}

export default confirm_modal_reducer;