import React from 'react';
import { Link } from 'react-router-dom';

class SidebarToggle extends React.Component {

    render() {
        return (
            <li>
                <Link to="#" data-toggle="control-sidebar">
                </Link>
            </li>
        );
    }
}

export default SidebarToggle;