import React from 'react';

class Role extends React.Component {

    render() {
        if(!this.props.logged_in_user) {
            return <li></li>
        }
        const { roles } = this.props.logged_in_user
        
        return (
            <li style={{padding: "6px 0 16px 25px"}}>
                <span className="navbar-role">Role:</span>
                <br />
                <span className="navbar-managing">{roles.map(r => r.name).join(',')}</span>
            </li>
        );
    }
}

export default Role;