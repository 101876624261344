import { roleLevel } from "./constants"

export const canViewAR = (roles, menuID) => {

    if(!roles || roles.length === 0) {
        // return true
        return false
    }
    
    if(!menuID) {
        return true
        // return false
    }

    let ar = roles[0].accessRights

    if(!ar[menuID]) {
        return true
        // return false
    }

    return ar[menuID].view
}

export const haveActionsAR = (roles, menuID, action) => {

    if(!roles || roles.length === 0) {
        return true
        // return false
    }
    
    if(!menuID) {
        return true
        // return false
    }

    let ar = roles[0].accessRights

    if(!ar[menuID]) {
        // return true
        return false
    }

    return ar[menuID][action]
}

export const isRoleHeadquarter = (roles) => {
    if(!roles || roles.length === 0) {
        return false
    }
    
    return roles.find(r => r.level === roleLevel.HEADQUARTERS) !== undefined
}