import React from 'react';

class Content extends React.Component {

    render() {
        const { children } = this.props;
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h3 className="title-page">
                        Location Setting
                    </h3>
                </section>
                <section className="content container-fluid">
                    {children}
                </section>
            </div>
        );
    }
}

export default Content;