import { Header } from 'components/Layout';
import React from 'react';

class NoSidebarLayout extends React.Component {
 
    render() {
        const { children } = this.props;
        return (
            <div className="hold-transition skin-black fixed sidebar-mini">
                <div className="wrapper">
                    <Header layout="NoSidebarLayout"/>
                    {children}
                </div>
            </div>
        );
    }
}

export default NoSidebarLayout;