export const roleLevel = {
    HEADQUARTERS: 'Headquarters',
    REGION: 'Region',
    SUB_REGION: 'Sub Region',
    PLASMA_CENTER: 'Plasma Center'
}

export const cslModules = {
    DASHBOARD: 'dashboard', // Dashboard
    PROCESSING_PROMOTION: 'processingPromotion',// Promotion
    APPROVED_PROMOTION: 'approvedPromotion',
    PUBLISHED_PROMOTION: 'publishedPromotion',
    NOTIFICATION: 'notification', // Notification Management
    CENTER_MANAGEMENT: 'centerManagement', // Center Management
    APP_USER_MANAGEMENT: 'appUserManagement', // App User Management
    REFERRAL: 'referral', // Referral
    SYSTEM_CONTENT: 'systemContent', // System Content
    EMPLOYEE_MANAGEMENT: 'employeeManagement', // Employee Management
    VISIT_REMINDERS: 'visitReminders', // Visit Reminders
    ANNOUNCEMENT: 'announcement', // Announcement
    COMMUNICATION_CADENCE: 'communicationCadence', // Communication Cadence
    REWARD_GAME: 'rewardGame',
    REDEMPTION_MANAGEMENT: 'rewardRedemption',
    PRODUCT_MANAGEMENT: 'rewardProduct',   
}

export const storage = {
    TOKEN: 'token',
    USER: 'user',
    PLASMA_CENTER_GENERAL_SETTING: 'plasma_center_general_setting',
    CURRENT_SELECTED_PLASMA_CENTER: 'current_selected_plasma_center',
    CURRENT_SELECTED_PLASMA_CENTER_ID: 'current_selected_plasma_center_id'
}

export const TIME_ZONE_TITLE = '(EST)'
export const TIME_ZONE = 'America/New_York'
