import React from 'react';

class EmptyLayout extends React.Component {
 
    render() {
        const { children } = this.props;
        return (
            <div className={this.props.className}>
                {children}
            </div>
        );
    }
}

export default EmptyLayout;