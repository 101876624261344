// Location
export const CREATE_LOCATION_URL = '/locations';
export const UPDATE_LOCATION_URL = '/locations/'; // /:_id
export const CHANGE_LOCATION_STATUS_URL = '/locations/status/'; // /:_id
export const FETCH_LOCATION_URL = '/staffs/specify-areas'; // Get operator's plasma center
export const FETCH_ALL_LOCATION_URL = '/locations'; //
export const GET_LOCATION_BY_ID_URL = '/locations/location-id/'; // /:_id

export const FETCH_HEAD_QUARTERS_GROUP_URL = '/locations/group/headquarters';

export const CREATE_FEE_STRUCTURE_URL = '/locations/fee-structures';
export const GET_FEE_STRUCTURE_URL = '/locations/fee-structures/lists';
export const UPDATE_FEE_STRUCTURE_URL = '/locations/fee-structures/update'; // /:_id
export const DELETE_FEE_STRUCTURE_URL = '/locations/fee-structures/delete'; // /:_id

// Donor
export const FETCH_DONOR_URL = '/donors/pagination';
export const FETCH_ALL_DONOR_URL = '/donors';
export const FREEZE_DONOR_BY_DONOR_ID_URL = '/donors/un-or-feeze';
export const SEND_RESET_PASSWORD = "/donors/send-reset-password/";// /:_id
export const SET_NEW_PASSWORD = "/donors/reset-password";
export const GET_DONOR_BY_TOKEN = "/donors/token-used/";// /:_token
export const CHECK_EXIST_DONORS_WITH_IDS = "/donors/check-exist-donors";

// Appointment
export const FETCH_APPOINTMENT_URL = '/appointments'; // /appointments?day=2020-01-03
export const FETCH_APPOINTMENT_PAGINATION_URL = '/appointments/pagination'; // /appointments?day=2020-01-03
export const FETCH_APPOINTMENT_BY_MONTH_URL = '/appointments/calendar-info'; // /appointments/calendar-info?day=2020-01-03
export const UPDATE_STATUS_APPOINTMENT_BYID_URL = 'appointments/update-status/' //:_id

// Role
export const FETCH_ROLES_URL = '/roles';

// Employee
export const FETCH_EMPLOYEE_URL = '/staffs';
export const GET_EMPLOYEE_BY_ID_URL = '/staffs/staff-id'; // :_id
export const CREATE_EMPLOYEE_URL = '/staffs';
export const UPDATE_EMPLOYEE_BY_ID_URL = '/staffs/'; // :_id
export const STAFF_RESET_PASSWORD = '/staffs/reset-password';
export const STAFF_SEND_VERIFY_CODE = '/staffs/send-verify-code';
export const STAFF_SUBMIT_FORGET_PWD = '/staffs/submit-forget-password';
export const STAFF_UPDATE_STATUS = '/staffs/un-or-freeze';

// Auth
export const LOGIN_URL = '/auth/login';

// System content

// News and Events
export const FETCH_NEWS_EVENTS_URL = '/settings/news-events';
export const UPDATE_NEWS_EVENT_BY_ID_URL = '/settings/news-event/'; // /:_id
export const CREATE_NEWS_EVENT_URL = '/settings/news-event';
export const REMOVE_ACTIVE_ON_NEWS_EVENT_URL = '/settings/news-event/remove-active/';// /:_id (delete)

// Acheivement Rule
export const FETCH_ACHIEVEMENT_RULE_URL = '/settings/achievementS';
export const CREATE_UPDATE_ACHIEVEMENT_RULE_URL = '/settings/achievement'; // PK is Country + State

// Group Setting
export const FETCH_AGE_GROUP_URL = '/settings/age-groups';
export const CREATE_AGE_GROUP_URL = '/settings/age-group';
export const UPDATE_AGE_GROUP_URL = '/settings/age-group/'; // :_id
export const UPDATE_AGE_GROUP_STATUS_URL = '/settings/age-group/status/'; // :_id

export const FETCH_ACTIVENESS_GROUP_URL = '/settings/activeness-groups';
export const CREATE_ACTIVENESS_GROUP_URL = '/settings/activeness-group';
export const UPDATE_ACTIVENESS_GROUP_URL = '/settings/activeness-group/'; // :_id
export const UPDATE_ACTIVENESS_GROUP_STATUS_URL = '/settings/activeness-group/status/'; // :_id

export const FETCH_DONORS_GROUP_URL = '/settings/donor-groups';
export const CREATE_DONORS_GROUP_URL = '/settings/donor-group';
export const UPDATE_DONORS_GROUP_URL = '/settings/donor-group/'; // :_id
export const UPDATE_DONORS_GROUP_STATUS_URL = '/settings/donor-group/status/'; // :_id
export const DONOR_RESEND_FEEDBACK_NOTIFICATION = '/notification/donation_feedback/resend';

export const FETCH_LAPSED_TIME_GROUP_URL = '/settings/lapsed-time-groups';
export const CREATE_LAPSED_TIME_GROUP_URL = '/settings/lapsed-time-group';
export const UPDATE_LAPSED_TIME_GROUP_URL = '/settings/lapsed-time-group/'; // :_id
export const UPDATE_LAPSED_TIME_GROUP_STATUS_URL = '/settings/lapsed-time-group/status/'; // :_id

// Points Setting
export const FETCH_POINTS_SETTING_URL = '/settings/point';
export const UPDATE_POINTS_SETTING_URL = '/settings/point';

// App Health History Link Setting
export const FETCH_APP_HEALTH_LINK_URL = '/settings/app-health-link';
export const UPDATE_APP_HEALTH_LINK_URL = '/settings/app-health-link';

// Feedback Options
export const FETCH_FEEDBACK_OPTIONS_URL = '/feedbackOptions/getAll';
export const CREATE_FEEDBACK_OPTION_URL = '/feedbackOptions/create';
export const UPDATE_FEEDBACK_OPTION_URL = '/feedbackOptions/update';
export const UPDATE_FEEDBACK_OPTION_ACTIVE_URL = '/feedbackOptions/activate';
export const UPDATE_FEEDBACK_OPTION_DEACTIVE_URL = '/feedbackOptions/deactivate';

// Referal type
export const REFERAL_TYPE_ALL_URL = '/referral/fetch_all';
export const REFERAL_TYPE_BY_PAGINATION= '/referral/pagination';
export const REFERAL_TYPE_SHARED_BY_PAGINATION= '/referral/share/pagination';
export const REFERAL_TYPE_SHARED= '/referral/share/fetch_all';
export const REFERAL_TYPE_BY_CHECKIN= '/referral/operate/checkin';
export const REFERAL_TYPE_BY_COMPLETED= '/referral/operate/complete';
export const REFERAL_TYPE_BY_REJECTED= '/referral/operate/reject';
export const REFERAL_TYPE_FIND_BY_ID= '/referral/find';
export const REFERAL_TYPE_BY_CONTENT= '/referral/qr/content';

// Rewards
const REWARD_PATH = '/reward';
export const REWARD_PROGRAM_PATH = REWARD_PATH + '/program';
export const REWARD_BONUS_PATH = REWARD_PATH + '/bonus';
export const REWARD_ADJUST_PATH = REWARD_PATH + '/adjust';
export const REWARD_REDEEM_PATH = REWARD_PATH + '/redeem';
export const REWARD_DONOR_GROUP_PATH = REWARD_PATH + '/donorgroup';
export const REWARD_CENTER_STATUS_PATH = REWARD_PATH + '/center-status';
const REWARD_SETTING = REWARD_PATH + '/setting';
export const REWARD_SETTING_PRESET = REWARD_SETTING + '/monthly';
export const REWARD_SETTING_EMAIL = REWARD_SETTING + '/email';
export const REWARD_SETTING_FEEDBACK = REWARD_SETTING + '/feedback';
export const REWARD_SETTING_REFERRAL = REWARD_SETTING + '/referral';
export const REWARD_SETTING_REDEEM = REWARD_SETTING + '/redeem';
export const REWARD_SETTING_REDEEM_ACTIVE = REWARD_SETTING + '/redeem-active';
export const REWARD_SETTING_QUOTA = REWARD_SETTING + '/point-quota';
export const REWARD_SETTING_EXPIRATION = REWARD_SETTING + '/expiration';
export const REWARD_SETTING_BONUS = REWARD_SETTING + '/member-bonus';
export const REWARD_SETTING_SETTINGS = REWARD_SETTING + '/settings';
//for testing only
export const REWARD_VIEW_PATH='/reward/view';

// App Feedback
const APPFEEDBACK_PATH = '/app-feedback';
export const APPFEEDBACK_PRODUCT_PATH = APPFEEDBACK_PATH + '/product';
export const APPFEEDBACK_BUGS_PATH = APPFEEDBACK_PATH + '/bugs';
export const APPFEEDBACK_USER_PATH = APPFEEDBACK_PATH + '/user';

// Why Donate
export const WHYDONATE_PATH = '/why-donate';

// Update image base64
export const UPLOAD_IMAGE_BASE64_URL = 'image_upload/upload/base64_image/single';

// Redeemtion
export const FETCH_REWARD_REDEEM = '/redeem-orders/lists';
export const UPDATE_REWARD_REDEEM_STATUS = '/redeem-orders/update-status';
export const FETCH_REWARD_PRODUCT = '/redeem-items/lists';
export const CREATE_REWARD_PRODUCT = '/redeem-items/create';
export const UPDATE_REWARD_PRODUCT = '/redeem-items/update';
export const GET_REWARD_PRODUCT_BY_ID = '/redeem-items/detail'
export const UPDATE_REWARD_PRODUCT_STATUS = '/redeem-items/un-or-freeze';
export const FETCH_REWARD_FAST_CASH = '/redeem-fast-cash/list';
export const CREATE_REWARD_FAST_CASH = '/redeem-fast-cash/create';
export const UPDATE_REWARD_FAST_CASH = '/redeem-fast-cash/update';
export const GET_REWARD_FAST_CASH_BY_ID = '/redeem-fast-cash/detail'
export const UPDATE_REWARD_FAST_CASH_STATUS = '/redeem-fast-cash/changing-freeze';
export const GET_REDEEM_SETTING = '/redeem/setting'
export const UPDATE_REDEEM_SETTING = '/redeem/setting/update-can-redeem';
