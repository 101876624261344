import { 
    FETCH_LOCATIONS, 
    FETCH_LOCATIONS_COMPLETE,
    FETCH_LOCATIONS_ERROR,
} from '../actions/location.actions';

const location_reducer = (state = {
    plasma_centers: [],
    fetch_plasma_centers_loading: false,
    fetch_plasma_centers_error: false,

}, action) => {
    switch (action.type) {
        case FETCH_LOCATIONS:
            return { ...state, fetch_plasma_centers_loading: true, fetch_plasma_centers_error: false };
        case FETCH_LOCATIONS_COMPLETE:
            return { ...state, plasma_centers: action.payload, fetch_plasma_centers_loading: false, fetch_plasma_centers_error: false }
        case FETCH_LOCATIONS_ERROR:
            return { ...state, fetch_plasma_centers_loading: false, fetch_plasma_centers_error: true };
        default:
            return state;
    }
}

export default location_reducer;