import { 
    FETCH_APPOINTMENTS_BY_MONTH, 
    FETCH_APPOINTMENTS_BY_MONTH_COMPLETE,
    FETCH_APPOINTMENTS_BY_MONTH_ERROR,
} from '../actions/calendar.actions';

const calendar_reducer = (state = {
    appointments: [],
    fetch_calendar_by_month_loading: false,
    fetch_calendar_by_month_error: false
}, action) => {
    switch (action.type) {
        case FETCH_APPOINTMENTS_BY_MONTH:
            return { ...state, fetch_calendar_by_month_loading: true, fetch_calendar_by_month_error: false };
        case FETCH_APPOINTMENTS_BY_MONTH_COMPLETE:
            return { ...state, appointments: action.payload, fetch_calendar_by_month_loading: false, fetch_calendar_by_month_error: false }
        case FETCH_APPOINTMENTS_BY_MONTH_ERROR:
            return { ...state, fetch_calendar_by_month_loading: false, fetch_calendar_by_month_error: true };
        default:
            return state;
    }
}

export default calendar_reducer;