import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { HIDE_MESSAGE_MODAL } from '../../redux/actions/message_modal.actions';

class MessageModal extends React.Component {

    constructor(props) {
        super(props)

        this.timer = null
    }
    
    closeModal = () => {
        this.props.dispatch({ type: HIDE_MESSAGE_MODAL})
    }

    render() {
        const { show, message, autoDismiss, type } = this.props.modalOption;
        const label = message instanceof Object ? "" : message

        if(show && autoDismiss) {
            this.timer = setTimeout(this.closeModal, 2000)
        } else if (this.timer) {
            clearTimeout(this.timer)
        }

        return (
                <Modal 
                    show={show}
                    backdrop={autoDismiss ? true : "static"}
                    keyboard={autoDismiss}
                    backdropClassName="message-modal-backdrop"
                    onHide={this.closeModal.bind(this)}
                    dialogClassName="customSucsess-modal">
                    <Modal.Body className={"modal-body modal-body-sm" }>
                        {type ? 
                            <div className="text-center">
                                <img 
                                    style={{ maxWidth: "45px" }} 
                                    alt="" 
                                    src={
                                        type === 'success' ? require("assets/img/other-icon/ic_success.png") : type === 'warning' ?
                                        require("assets/img/other-icon/warning-icon.jpg") : require("assets/img/other-icon/fail-icon.jpg")
                                    } 
                                />
                                <br />
                                <h5 style={{ marginBottom: "0" }}>{label}</h5>
                            </div> : 
                            <h5>{label}</h5>
                        }
                    </Modal.Body>
                </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    modalOption: state.message_modal_reducer
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);