export const getDonorProfileImageUrl = (donor) => {
    if(donor !== null) {
        if(donor.donorThumbnailPath) {
            return donor.donorThumbnailPath
        } else {
            return require('assets/temp/default_profile_picture.png')
        }
    }

    return require('assets/temp/default_profile_picture.png')
}

export const getStaffImageUrl = (staff) => {
    if(staff !== null) {
        if(staff.avatar) {
            return staff.avatar
        } else {
            return require('assets/temp/default_profile_picture.png')
        }
    }
}