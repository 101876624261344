import React from 'react'
import Page from './Page';

class PageLoading extends React.Component {

    render() {
        return (
            <Page>
                <h1 className="text-center">Loading...</h1>
            </Page>
        )
    }
}

export default PageLoading