import React from 'react';
import MenuItem from './MenuItem';
import { isSameRoute } from '../share/stringHelper';
import { connect } from 'react-redux';
import { canViewAR } from '../share/accessRightHelper';
import { cslModules } from '../share/constants';

const navItems = [
    {
        id: cslModules.DASHBOARD,
        to: "/",
        icon: "dashboard-black.png",
        iconSelected: "dashboard-red.png",
        title: "Dashboard",
        tooltip: null,
        enable: true
    },
    {
        id: "marketing",
        icon: "marketing-black.png",
        iconSelected: "marketing-red.png",
        title: "Marketing",
        enable: true,
        children: [
            {
                id: cslModules.PROCESSING_PROMOTION,
                to: "/promotion/list/news/processing",
                title: "Create Content",
                tooltip: null,
                enable: true
            },
            {
                id: cslModules.APPROVED_PROMOTION,
                to: "/promotion/list/news/approved",
                title: "Approved Content",
                tooltip: null,
                enable: true
            },
            {
                id: cslModules.PUBLISHED_PROMOTION,
                to: "/promotion/list/news/published",
                title: "Published Content",
                tooltip: null,
                enable: true
            },
            // {
            //     to: "/promotion/statistics/newslist",
            //     title: "Statistics",
            //     tooltip: "Coming soon",
            //     enable: true
            // }
        ]
    },
    {
        id: "rewards",
        to: "#rewards",
        icon: "reward-black.png",
        iconSelected: "reward-red.png",
        title: "Rewards",
        enable: true,
        children: [
            {
                id: "rewardsDonation",
                to: "/rewards/programs",
                title: "Donation Rewards",
                tooltip: "View, edit, and create donation rewards",
                enable: true
            },
            {
                id: "rewardsBonus",
                to: "/rewards/bonuses",
                title: "Special Occasions",
                tooltip: "View, edit, and create bonus rewards",
                enable: true
            },
            {
                id: "rewardsRedeem",
                to: "/rewards/redeem",
                title: "Reward Redemption",
                tooltip: "View reward redemption history",
                enable: true
            },
            {
                id: "rewardsAdjust",
                to: "/rewards/adjust",
                title: "Reward Adjustment",
                tooltip: "Vie manual reward adjustment history",
                enable: true
            },
            {
                id: "rewardsSetting",
                to: "/rewards/settings",
                title: "Reward Settings",
                tooltip: "Manage reward settings",
                enable: true
            },
            {
                id: "rewardsView",
                to: "/rewards/view",
                title: "Reward View",
                tooltip: "View rewards",
                enable: false
            },
            {
                id: "rewardsFastCash",
                to: "/rewards/fastcash",
                title: "Fast Cash Response",
                tooltip: "Fast Cash Response",
                enable: true
            }
        ]
    },
    {
        id: "redemptions",
        to: "#redemptions",
        icon: "reward-black.png",
        iconSelected: "reward-red.png",
        title: "Redemptions",
        enable: true,
        children: [
            {
                id: "rewardRedemption",
                to: "/redemptions",
                title: "Redemptions",
                tooltip: "View and approve the redeem.",
                enable: true
            },
            {
                id: "rewardProduct",
                to: "/redemptions/products",
                title: "Products",
                tooltip: "View, edit, and create products",
                enable: true
            }
        ]
    },
    {
        id: cslModules.NOTIFICATION,
        to: "/notification",
        icon: "notification-black.png",
        iconSelected: "notification-red.png",
        title: "Notification Management",
        enable: true
    },
    {
        id: cslModules.CENTER_MANAGEMENT,
        to: "/location",
        icon: "location-black.png",
        iconSelected: "location-red.png",
        title: "Center Management",
        tooltip: null,
        enable: true
    },
    {
        id: cslModules.APP_USER_MANAGEMENT,
        to: "/member",
        icon: "member-black.png",
        iconSelected: "member-red.png",
        title: "App User Management",
        tooltip: null,
        enable: true
    },
    {
        id: cslModules.REFERRAL,
        to: "/referral-list",
        icon: "referral-black.png",
        iconSelected: "referral-red.png",
        title: "Referral",
        enable: true
    },
    {
        id: cslModules.SYSTEM_CONTENT,
        to: "/content/group",
        icon: "system-content-black.png",
        iconSelected: "system-content-red.png",
        title: "System Content",
        tooltip: null,
        enable: true
    },
    {
        id: cslModules.EMPLOYEE_MANAGEMENT,
        to: "/employee",
        icon: "role-black.png",
        iconSelected: "role-red.png",
        title: "Employee Management",
        tooltip: null,
        enable: true
    },
    {
        id: cslModules.VISIT_REMINDERS,
        to: "/visitreminder",
        icon: "appointment-black.png",
        iconSelected: "appointment-red.png",
        title: "Visit Reminders",
        tooltip: null,
        enable: true
    },
    {
        id: cslModules.ANNOUNCEMENT,
        to: "/announcement",
        icon: "notification-black.png",
        iconSelected: "notification-red.png",
        title: "Announcement",
        enable: true
    },
    {
        id: cslModules.COMMUNICATION_CADENCE,
        to: "/communication",
        icon: "communication-cadence-black.png",
        iconSelected: "communication-cadence-red.png",
        title: "Communication Cadence",
        enable: true
    },
    {
        id: cslModules.REWARD_GAME,
        to: "/rewardgame/rewarditem",
        icon: "reward-game-black.png",
        iconSelected: "reward-game-red.png",
        title: "Reward Game",
        enable: true
    },
    {
        id: "appFeedback",
        to: "/appfeedback",
        icon: "donor-app-feedback-black.png",
        iconSelected: "donor-app-feedback-red.png",
        title: "Donor App Feedback",
        enable: true
    },
    {
        id: "whyDonate",
        to: "/whydonate",
        icon: "why-donate-content-black.png",
        iconSelected: "why-donate-content-red.png",
        title: "Why-Donate Content",
        enable: true
    }
]

// modified to include submenu -- Hong 5/20/2020
export const getMenuByPath = (pathname) => {
    let menuItem = null;
    outerloop: for(let i=0; i<navItems.length; i++) {
        if(isSameRoute(pathname, navItems[i].to)) {
            menuItem = navItems[i];
            break;
        }
        if(!navItems[i].children) continue;
        for(let j=0; j<navItems[i].children.length; j++) {
            if(isSameRoute(pathname, navItems[i].children[j].to)) {
                menuItem = navItems[i].children[j];
                break outerloop;
            }
        }
    }
    return menuItem;
}

export const getFirstAvailbleRoute = (roles) => {
    const menu = navItems.find(n => canViewAR(roles, n.id))
    if(menu) {
        if(menu.to) {
            return menu.to
        } else if(menu.children) {
            const item = menu.children.find(m => canViewAR(roles, m.id))
            if (item && item.to) {
                return item.to
            } 
        }
    }
    
    return '/login';
}

class SidebarLeft extends React.Component {

    constructor() {
        super()

        this.state = {
            activeItem: navItems[0].to,
            hoverItem: null
        }
    }

    componentDidMount() {
        this._handleClick(window.location.pathname)
        var n = document.getElementsByClassName('main-tooltip');
        var originPadding = 18;
        var originHeight = 1000;

        var height = window.innerHeight;
        var newPadding = originPadding * height / originHeight;
        var i;
        for (i = 0; i < n.length; i++) {
            n[i].style.paddingTop = newPadding + "px";
            n[i].style.paddingBottom = newPadding + "px";
        }

        function resizeMenu() {
            var height = window.innerHeight;
            var newPadding = originPadding * height / originHeight;
            for (i = 0; i < n.length; i++) {
                n[i].style.paddingTop = newPadding + "px";
                n[i].style.paddingBottom = newPadding + "px";
            }
        }
        window.addEventListener('resize', resizeMenu);
    }

    /**
     * Handle on click nav item to set active item
     * 
     * @param {string} menuItem 
     */
    _handleClick(menuItem) {
        this.setState({
            activeItem: menuItem
        })
    }

    /**
     * Handle on mouse enter on nav item to change icon color
     * 
     * @param {string} menuItem 
     */
    _onMouseEnter(menuItem) {
        this.setState({
            hoverItem: menuItem
        })
    }

    /**
     * Handle on mouse out on nav item to change icon color
     */
    _onMouseLeave() {
        this.setState({
            hoverItem: null
        })
    }

    render() {
        if(!this.props.logged_in_user) {
            return <ul></ul>
        }
        const { roles } = this.props.logged_in_user

        return (
            <ul className="sidebar-menu" data-widget="tree">
                {navItems.filter(n => {
                    return (n.enable && canViewAR(roles, n.id))
                }).map((menu, index) => {
                    const {to} = menu
                    if (!menu.enable) return "";
                    const menuIcon = (isSameRoute(this.state.activeItem, menu.to) ||
                        isSameRoute(this.state.hoverItem, menu.to) ? menu.iconSelected : menu.icon);
                    return (
                        <MenuItem 
                            activeClass={isSameRoute(this.state.activeItem, menu.to) ? "active" : null} 
                            key={index} 
                            menu={menu} 
                            menuIcon={menuIcon}
                            roles={roles}
                            handleClick={this._handleClick.bind(this, to)}
                            handleMouseEnter={this._onMouseEnter.bind(this, to)}
                            handleMouseLeave={this._onMouseLeave.bind(this)}
                        />
                    )
                })}
            </ul>
        );
    }
}

const mapStateToProps = (state) => ({
    logged_in_user: state.staff_reducer.logged_in_user
})

export default connect(mapStateToProps)(SidebarLeft);
