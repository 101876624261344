import React, { useState } from 'react'
import { Link } from "react-router-dom";
import useCollapse from 'react-collapsed';
import { canViewAR } from '../share/accessRightHelper';

const MenuItem = ({
    menu,
    menuIcon,
    activeClass,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    roles
}) => {
    const {
        to,
        tooltip,
        title,
        children
    } = menu

    const [activeItem, setActiveItem] = useState("")

    const { getCollapseProps, getToggleProps} = useCollapse({
        defaultOpen: false
    });

    return (<>
        {children ?
            (
                <li >
                    <a
                        to="#"
                        style={{
                            position: 'relative'
                        }}
                        {...getToggleProps()}
                        title={tooltip? tooltip : ''}
                    >
                        <span className="dashboard-icon">
                            <img src={require('assets/img/menu/' + menu.icon)} alt="dashboard" />
                        </span>
                        {tooltip ? <span className="tooltiptext">{tooltip}</span> : null}
                        <span>{title}</span>
                        <img
                            style={dropdownStyle}
                            src={require("assets/img/menu/arrow-down.png")}
                            alt="arrow-down" />
                    </a>
                    <ul className="nav nav-treeview" {...getCollapseProps()}>
                        {
                            children.filter(c => (c.enable&&canViewAR(roles, c.id))).map((sm, index) => {
                                return (
                                    <li className="nav-item" key={index}>
                                        <Link
                                            to={sm.to}
                                            onClick={() => {
                                                setActiveItem(sm.to)
                                                handleClick()
                                            }}
                                            title={sm.tooltip? sm.tooltip : ''}
                                        >
                                            <span style={{
                                                marginLeft: '50px',
                                                color: activeItem === sm.to ? "#e52d47" : null
                                            }}>{sm.title}</span>
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </li>
            )
            :
            (
                <li className={activeClass}>
                    <Link
                        to={to}
                        className="main-tooltip"
                        onClick={handleClick}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <span className="dashboard-icon">
                            <img src={require('assets/img/menu/' + menuIcon)} alt="dashboard" />
                        </span>
                        {tooltip ? <span className="tooltiptext">{tooltip}</span> : null}
                        <span>{title}</span>
                    </Link>
                </li>
            )

        }
    </>

    )
}

const dropdownStyle = {
    position: 'absolute',
    right: '13px',
    width: '10px',
    marginTop: '9px'
}

export default MenuItem