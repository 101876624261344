import React from 'react'
import Loader from './Loader'

const BoxLoader = ({ show, loadingStyle }) => {
    if(!show) {
        return null
    }
    return <div aria-busy="true" className={ loadingStyle === 2 ? "loader-wrapper-fixed" : "loader-wrapper" }>
        <Loader color="#C6322C" height={40} width={40} visible={true} className="loader-content" />
    </div>
}

export default BoxLoader;